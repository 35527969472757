<template>
<div>

</div>
</template>

<script>
export default {
  name: "service",
  data() {
    return {
      show: true,
    };
  },
}
</script>

<style scoped>
.phone{
 height:2.22rem ;
  text-align: center;
  line-height: 2.22rem;
  font-size: 0.58rem;
  font-family: xiaocheng;
  font-weight: bold;
  color: #333333;
}
</style>
